
import { cva } from 'class-variance-authority'
export default {
  name: 'TextComponent',
  props: {
    as: {
      type: String,
      default: 'div',
      validator: (value) => ['div', 'p', 'span', 'li'].includes(value),
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) =>
        ['micro', 'small', 'medium', 'large', 'spaced'].includes(value),
    },
    weight: {
      type: String,
      default: 'regular',
      validator: (value) => ['regular', 'bold'].includes(value),
    },
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    style() {
      const body = cva('has-link default-list', {
        variants: {
          size: {
            micro: 'text-xs',
            small: 'text-sm',
            medium: 'text-base',
            large: 'text-base md:text-2xl',
            spaced: 'text-xxs md:text-2xs uppercase tracking-[0.3em]'
          },
          weight: {
            regular: 'font-normal',
            bold: 'font-bold',
          },
        },
      })
      return body({ size: this.size, weight: this.weight })
    },
  },
}
